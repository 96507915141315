import Swiper from "swiper";
import { Autoplay } from "swiper/modules";
import "swiper/css";

const initMarqueeSlider = () => {
  const marqueeElement = document.querySelector(".marquee");
  if (!marqueeElement) {
    console.error("Marquee element not found");
    return;
  }

  new Swiper(marqueeElement, {
    modules: [Autoplay],
    slidesPerView: "auto",
    spaceBetween: 70,
    loop: true,
    speed: 12000,
    allowTouchMove: false,
    draggable: false,
    centeredSlides: true,
    loopedSlides: 12,
    observer: true,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
    },
    on: {
      beforeInit: function () {
        this.el.style.transform = "translate3d(0, 0, 0)";
      },
      init: function () {
        this.el.style.willChange = "transform";
      },
    },
  });
};

const initMarqueeSliderObserver = () => {
  const marqueeElement = document.querySelector(".marquee");
  if (!marqueeElement) {
    console.error("Marquee element not found");
    return;
  }

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          initMarqueeSlider();
          observer.unobserve(entry.target);
        }
      });
    },
    { rootMargin: "100px" }
  );

  observer.observe(marqueeElement);
};

export { initMarqueeSliderObserver };
