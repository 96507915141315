function createDots() {
  const container = document.querySelector(".dots-background");
  if (!container) {
    console.error("Dots container not found");
    return;
  }

  const dotsCount = 400;
  container.innerHTML = "";

  for (let i = 0; i < dotsCount; i++) {
    const dot = document.createElement("div");
    dot.className = "dot";
    dot.style.left = `${Math.random() * 100}%`;
    dot.style.top = `${Math.random() * 100}%`;
    dot.style.animationDelay = `${Math.random() * 3}s`;
    container.appendChild(dot);
  }
}

export function checkViewportAndCreateDots() {
  const container = document.querySelector(".dots-background");
  const pageWrap = document.querySelector(".page-wrap");
  if (!container || !pageWrap) {
    console.error("Required elements not found");
    return;
  }

  if (pageWrap.offsetWidth > 1905) {
    createDots();
  } else {
    container.innerHTML = "";
  }
}
