export const clearNavState = (navEl) => {
  const currentActive = navEl.querySelector("a.active");
  if (currentActive) {
    currentActive.classList.remove("active");
  }
  return null;
};

export const smoothScrollTop = (e) => {
  e.preventDefault();
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};
