function setFavicon(theme) {
  const link =
    document.querySelector("link[rel='icon']") ||
    document.createElement("link");
  link.rel = "icon";
  link.href =
    theme === "dark"
      ? "static/favicons/favicon.png"
      : "static/favicons/favicon_black.png";
  document.head.appendChild(link);
}

function setupThemeDetection() {
  const matchMedia = window.matchMedia("(prefers-color-scheme: dark)");
  const updateFavicon = (e) => setFavicon(e.matches ? "dark" : "light");

  setFavicon(matchMedia.matches ? "dark" : "light");
  matchMedia.addEventListener("change", updateFavicon);
}

function favicon() {
  try {
    setupThemeDetection();
  } catch (error) {
    console.error("Error setting up theme detection:", error);
  }
}

export default favicon;
