import Swiper from "swiper";
import { Navigation, Pagination, EffectFade, Autoplay } from "swiper/modules";
import { isXL } from "./mq.js";

import "swiper/css";

const cases = [
  {
    images: [
      "static/images/cases/opendance/Open-1.webp",
      "static/images/cases/opendance/Open-2.webp",
      "static/images/cases/opendance/Open-3.webp",
      "static/images/cases/opendance/Open-4.webp",
    ],
    title: "OpenDance Academy",
    links: [
      "https://apps.apple.com/ru/app/opendance-academy/id1372889900",
      "https://play.google.com/store/apps/details?id=com.alef.opendance&hl=en&pli=1",
    ],
  },
  {
    images: [
      "static/images/cases/passport/Passport-1.webp",
      "static/images/cases/passport/Passport-2.webp",
      "static/images/cases/passport/Passport-3.webp",
      "static/images/cases/passport/Passport-4.webp",
    ],
    title: "PassPort",
    links: ["#", "#"],
  },
  {
    images: [
      "static/images/cases/elegancecatering/Elegance-1.webp",
      "static/images/cases/elegancecatering/Elegance-2.webp",
      "static/images/cases/elegancecatering/Elegance-3.webp",
      "static/images/cases/elegancecatering/Elegance-4.webp",
    ],
    title: "Elegance Catering",
    links: ["https://elegancecatering.ru/"],
  },
  {
    images: [
      "static/images/cases/motive8/Motiv-1.webp",
      "static/images/cases/motive8/Motiv-2.webp",
      "static/images/cases/motive8/Motiv-3.webp",
      "static/images/cases/motive8/Motiv-4.webp",
    ],
    title: "Motiv8",
    links: [
      "https://apps.apple.com/us/app/motiv8-ideas-goals-tasks/id1666258305",
      "https://play.google.com/store/apps/details?id=com.summer.motiv8&pcampaignid=web_share",
    ],
  },
  {
    images: [
      "static/images/cases/bavaria/Bavaria-1.webp",
      "static/images/cases/bavaria/Bavaria-2.webp",
      "static/images/cases/bavaria/Bavaria-3.webp",
    ],
    title: "Bavaria",
    links: ["https://bavaria.mosbrew.ru"],
  },
];

const CLASS_NAMES = {
  swiperWrapper: "case-item-swiper-wrapper",
  caseTitle: "case-item-title",
  nextButton: "case-item-button-next",
  prevButton: "case-item-button-prev",
  swiper: "case-item-swiper",
  pagination: "case-item-swiper-pagination",
  bullet: "case-item-swiper-bullet",
  bulletActive: "active",
  loading: "loading",
  zoomIn: "zoomIn",
  pointerEventsNone: "pointer-events-none",
  platformsList: "case-item__platforms-list",
  bulletFadeIn: "bullet-fade-in",
};

const initCaseSwiper = () => {
  const slidesContainer = document.getElementsByClassName(
    CLASS_NAMES.swiperWrapper
  )[0];
  const caseTitleEl = document.getElementsByClassName(CLASS_NAMES.caseTitle)[0];
  const nextCaseBtn = document.getElementsByClassName(
    CLASS_NAMES.nextButton
  )[0];
  const prevCaseBtn = document.getElementsByClassName(
    CLASS_NAMES.prevButton
  )[0];
  let currentCaseIndex = 0;

  const caseItemSwiper = new Swiper(`.${CLASS_NAMES.swiper}`, {
    modules: [Navigation, Pagination, EffectFade, Autoplay],
    loop: false,
    slidesPerView: 1,
    spaceBetween: 0,
    effect: "fade",
    fadeEffect: { crossFade: true },
    speed: 1000,
    autoplay: { delay: 2000, disableOnInteraction: false },
    pagination: {
      enabled: true,
      el: `.${CLASS_NAMES.pagination}`,
      bulletClass: CLASS_NAMES.bullet,
      bulletElement: "button",
      bulletActiveClass: "active",
      clickable: true,
      renderBullet: (index, className) => {
        const { images } = cases[currentCaseIndex];
        const paginationEl = document.querySelector(
          `.${CLASS_NAMES.pagination}`
        );

        if (index === 0) {
          paginationEl.innerHTML = "";
        }

        if (index >= images.length) {
          return "";
        }

        const renderDelay = index * 100;
        setTimeout(() => {
          const existingBullet = paginationEl.querySelector(
            `[data-index="${index}"]`
          );
          if (existingBullet) return;

          const bullet = document.createElement("button");
          bullet.type = "button";
          bullet.className = `${className} loadable ${
            index === 0 ? "active" : ""
          }`;
          bullet.setAttribute("data-index", index);
          bullet.style.opacity = "0";
          bullet.innerHTML = `<img src="${images[index]}" loading="lazy"/>`;

          paginationEl.appendChild(bullet);

          requestAnimationFrame(() => {
            bullet.style.transition = "opacity 0.3s ease-in-out";
            bullet.style.opacity = "1";
          });

          bullet.addEventListener("click", () => {
            caseItemSwiper.slideTo(index);
          });
        }, renderDelay);

        return "";
      },
    },
    on: {
      slideChange: function () {
        setTimeout(() => {
          const bullets = document.querySelectorAll(`.${CLASS_NAMES.bullet}`);
          if (!bullets || bullets.length === 0) return;
          bullets.forEach((bullet, index) => {
            if (index === this.activeIndex) {
              bullet.classList.add(CLASS_NAMES.bulletActive);
            } else {
              bullet.classList.remove(CLASS_NAMES.bulletActive);
            }
          });
        }, 0);
      },
      init: function () {
        const firstBullet = this.pagination.bullets[0];
        if (firstBullet) {
          firstBullet.classList.add("active");
        }
      },
    },
  });

  const renderSlides = ({ images, links }) => {
    slidesContainer.innerHTML = images
      .map(
        (image) => `
      <div class="swiper-slide">
        <img src="${image}" alt="">
      </div>
    `
      )
      .join("");

    const platformsList = document.querySelector(
      `.${CLASS_NAMES.platformsList}`
    );
    platformsList.innerHTML = links
      .map((link, index) => {
        const icon = index === 0 ? "apple" : "android";
        return link === "#"
          ? `
        <li>
          <img src="static/images/icn/icn_${icon}.svg" alt="${icon}" loading="lazy"/>
        </li>`
          : `
        <li>
          <a href="${link}" target="_blank">
            <img src="static/images/icn/icn_${icon}.svg" alt="${icon}" loading="lazy"/>
          </a>
        </li>`;
      })
      .join("");
    caseItemSwiper.update();
  };

  const changeCase = (direction) => {
    currentCaseIndex =
      (currentCaseIndex + direction + cases.length) % cases.length;
    caseItemSwiper.el.classList.add(CLASS_NAMES.loading);
    caseTitleEl.classList.add(CLASS_NAMES.loading);
    nextCaseBtn.classList.add(CLASS_NAMES.pointerEventsNone);
    prevCaseBtn.classList.add(CLASS_NAMES.pointerEventsNone);

    setTimeout(() => {
      renderSlides(cases[currentCaseIndex]);
      caseTitleEl.innerHTML = cases[currentCaseIndex].title;
      caseItemSwiper.pagination.render();
      caseItemSwiper.pagination.update();
      caseItemSwiper.slideTo(0, 0);
      caseItemSwiper.el.classList.remove(CLASS_NAMES.loading);
      caseTitleEl.classList.remove(CLASS_NAMES.loading);
      nextCaseBtn.classList.remove(CLASS_NAMES.pointerEventsNone);
      prevCaseBtn.classList.remove(CLASS_NAMES.pointerEventsNone);
      caseItemSwiper.autoplay.start();
    }, 500);
  };

  nextCaseBtn.addEventListener("click", () => changeCase(1));
  prevCaseBtn.addEventListener("click", () => changeCase(-1));

  renderSlides(cases[currentCaseIndex]);
  caseItemSwiper.init();
};

const initCaseSwiperObserver = () => {
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          initCaseSwiper();
          observer.unobserve(entry.target);
        }
      });
    },
    { rootMargin: "100px" }
  );

  const swiperElement = document.querySelector(`.${CLASS_NAMES.swiper}`);
  if (swiperElement) {
    observer.observe(swiperElement);
  }
};

export default initCaseSwiperObserver;
