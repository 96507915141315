const observer = () => {
  if ("IntersectionObserver" in window) {
    const handleIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setBackground(entry.target);
          observer.unobserve(entry.target);
        }
      });
    };

    const setBackground = (element) => {
      const imageUrl = element.dataset.bgimage;
      if (element.classList.contains("service-item")) {
        element.addEventListener(
          "mouseenter",
          () => {
            element.style.setProperty("--background-image", `url(${imageUrl})`);
          },
          { once: true }
        );
      } else {
        element.style.backgroundImage =
          element.id === "section-about"
            ? `linear-gradient(rgba(13,22,29,0.8), rgba(13,22,29,0.8)), url('${imageUrl}')`
            : `url('${imageUrl}')`;
      }
    };

    const backgrounds = document.querySelectorAll(".lazyload-bg");
    const observer = new IntersectionObserver(handleIntersection, {
      rootMargin: "100px",
    });
    backgrounds.forEach((background) => observer.observe(background));
  } else {
    const backgrounds = document.querySelectorAll(".lazyload-bg");
    backgrounds.forEach(setBackground);
  }
};

export default observer;
