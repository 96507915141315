export async function sendToTelegram(formData, chatId, botToken) {
  const file = formData.get("feedback-file");
  const hasFile = file && file instanceof File;
  const currentUrl = window.location.href;

  const isModalForm =
    !formData.get("email") && !formData.get("comment") && !hasFile;

  const messageData = {
    chat_id: chatId,
    text: isModalForm
      ? `Новое сообщение с ${
          currentUrl || "лендинга https://alef2024.alef.dev/"
        }
        
Имя: ${formData.get("name")}
Телефон: ${formData.get("tel")}`
      : `Новое сообщение с ${
          currentUrl || "лендинга https://alef2024.alef.dev/"
        }

Имя: ${formData.get("name")}
Телефон: ${formData.get("tel")}
Email: ${formData.get("email")}
Комментарий: ${formData.get("comment")?.trim() || "Без комментария"}
${hasFile ? `Файл: ${file.name}` : "Без файла"}`,
  };

  try {
    const messageResponse = await fetch(
      `https://api.telegram.org/bot${botToken}/sendMessage`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(messageData),
      }
    );
    const messageResult = await messageResponse.json();

    if (hasFile) {
      const formDataForFile = new FormData();
      formDataForFile.append("chat_id", chatId);
      formDataForFile.append("document", file);

      const fileResponse = await fetch(
        `https://api.telegram.org/bot${botToken}/sendDocument`,
        {
          method: "POST",
          body: formDataForFile,
        }
      );
      const fileData = await fileResponse.json();

      return messageResult.ok && fileData.ok;
    }

    return messageResult.ok;
  } catch (error) {
    console.error("Error sending to Telegram:", error);
    return false;
  }
}

export function showModalState(
  state,
  formState,
  successState,
  errorState,
  source = "feedback"
) {
  formState.classList.remove("active");
  successState.classList.remove("active");
  errorState.classList.remove("active");
  state.classList.add("active");
  modal.classList.add("opened");
  modal.dataset.source = source;
}

export function resetFileInput(
  fileInput,
  fileInputName,
  fileDeleteButton,
  submitButton
) {
  fileInput.value = "";
  fileInputName.innerText = "";
  fileInput.parentElement.classList.remove("active");
  fileDeleteButton.remove();
  submitButton.disabled = false;
  const errorLabel = document.querySelector("#form-file-input-error");
  if (errorLabel) {
    errorLabel.textContent = "";
    errorLabel.style.display = "none";
  }
}

export function sanitizeComment(input) {
  if (!input) return "";
  return input
    .trim()
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;")
    .replace(/`/g, "&#x60;")
    .replace(/\r?\n/g, "<br>")
    .substring(0, 1000);
}

export const createErrorMessage = (message, isStatic = false) => {
  const errorMessage = document.createElement("div");
  errorMessage.className = "error-message";
  errorMessage.innerText = message;
  errorMessage.style.color = "#EF4444";
  errorMessage.style.fontSize = "0.75rem";
  errorMessage.style.position = isStatic ? "static" : "absolute";
  errorMessage.style.top = "0.125rem";
  errorMessage.style.left = "0.77rem";
  if (isStatic) errorMessage.style.marginTop = "0.25rem";
  return errorMessage;
};

export const validateFio = (fio) => {
  const trimmedFio = fio.trim();

  if (trimmedFio.length < 2 || trimmedFio.length > 50) {
    return {
      isValid: false,
      error: "Имя должно быть от 2 до 50 символов.",
    };
  }

  if (
    !/^[a-zA-ZА-Яа-яЁё]+(?:[\s-][a-zA-ZА-Яа-яЁё]+)*$/.test(trimmedFio) ||
    /^[\s-]+$/.test(trimmedFio)
  ) {
    return {
      isValid: false,
      error: "Недопустимый формат имени",
    };
  }

  return {
    isValid: true,
    error: null,
  };
};

export const validateEmail = (email) => {
  if (!email || email.length < 5 || email.length > 254) return false;

  const [localPart, domain] = email.split("@");
  if (!localPart || localPart.length < 1 || localPart.length > 64) return false;
  if (
    !domain ||
    domain.length > 255 ||
    domain.startsWith(".") ||
    domain.endsWith(".")
  )
    return false;

  const domainParts = domain.split(".");
  if (domainParts.some((part) => part.length > 63)) return false;

  if (email.includes("..")) return false;

  const emailRegex =
    /^[a-zA-Z0-9][a-zA-Z0-9._-]*[a-zA-Z0-9]@[a-zA-Z0-9][-a-zA-Z0-9.]*[a-zA-Z0-9]\.[a-zA-Z]{2,}$/;

  return emailRegex.test(email);
};

export const showFileError = (errorLabel, message) => {
  if (errorLabel) {
    errorLabel.textContent = message;
    errorLabel.style.color = "#EF4444";
    errorLabel.style.marginTop = "0.25rem";
    errorLabel.style.fontSize = "0.75rem";
    errorLabel.style.display = "block";
  }
};

export const handleFileInput = (
  file,
  fileInput,
  fileInputName,
  fileDeleteButton,
  maxFileSize,
  supportedExtensions,
  errorMessages,
  showFileError,
  submitButton
) => {
  const errorLabel = document.querySelector("#form-file-input-error");
  if (errorLabel) {
    errorLabel.textContent = "";
  }

  submitButton.disabled = false;

  if (file) {
    const fileName = file.name;
    const fileSize = file.size;
    const fileExtension = fileName.split(".").pop().toLowerCase();

    if (fileSize > maxFileSize) {
      showFileError(errorLabel, errorMessages.fileSizeExceeded);
      fileInput.value = "";
      submitButton.disabled = true;
      return;
    }

    if (!supportedExtensions.includes(fileExtension)) {
      showFileError(errorLabel, errorMessages.fileTypeInvalid);
      fileInput.value = "";
      submitButton.disabled = true;
      return;
    }

    const truncatedFileName =
      fileName.length > 10
        ? `${fileName.substring(0, 6)}...${fileExtension}`
        : fileName;
    fileInputName.innerText = truncatedFileName;
    fileInput.parentElement.classList.add("active");

    fileDeleteButton.innerText = "x";
    fileDeleteButton.type = "button";
    fileDeleteButton.classList.add("delete-button");
    fileDeleteButton.setAttribute("aria-label", "Удалить файл");
    fileInput.parentElement.appendChild(fileDeleteButton);
  }
};

export const validateInput = (
  input,
  requiredMessage,
  invalidMessage,
  validationFn,
  nameInputId
) => {
  if (!input.value) {
    input.classList.add("error");
    input.parentElement.appendChild(createErrorMessage(requiredMessage));
    return false;
  } else if (input.id === nameInputId) {
    const validation = validationFn(input.value);
    if (!validation.isValid) {
      input.classList.add("error");
      input.parentElement.appendChild(createErrorMessage(validation.error));
      return false;
    }
  } else if (!validationFn(input.value)) {
    input.classList.add("error");
    input.parentElement.appendChild(createErrorMessage(invalidMessage));
    return false;
  }
  input.classList.remove("error");
  return true;
};

export const validatePrivacyPolicy = (input, createError) => {
  if (!input.checked) {
    input.classList.add("error");
    input.parentElement.appendChild(createError("", true));
    return false;
  }
  input.classList.remove("error");
  return true;
};
