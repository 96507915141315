import { isValidPhoneNumber, mask } from "./phoneMask/mask";

import {
  createErrorMessage,
  validateFio,
  validateInput,
  validatePrivacyPolicy,
  showModalState,
  sendToTelegram,
} from "./utils/formUtils";

async function modalBtn() {
  const modal = document.querySelector("#modal");
  const modalContent = document.querySelector(".modal__content");
  const submitButton = document.querySelector(
    '.modal__right button[type="submit"]'
  );
  const buttonArrow = document.querySelector(".modal__arrow");
  const buttonSpinner = document.querySelector(".modal__spinner");
  const closeButton = document.querySelector(".modal__content-close");
  const form = document.querySelector("#form-modal");
  const privacyPolicyInputModal = document.querySelector(
    "#form-modal-privacy-policy"
  );
  const formState = document.querySelector(".modal__state--form");
  const successState = document.querySelector(".modal__state--success");
  const errorState = document.querySelector(".modal__state--error");
  const backButton = document.querySelector(".modal__back-btn");
  const retryButton = document.querySelector(".modal__retry-btn");

  if (!modal || !modalContent || !submitButton || !closeButton) {
    return;
  }

  form.setAttribute("novalidate", true);

  const phoneInput = document.querySelector("#form-modal-tel");
  if (phoneInput) {
    mask("#form-modal-tel");
  }

  const errorMessagesModal = {
    nameRequired: "Необходимо ввести Имя.",
    nameInvalid: "Имя должно быть от 2 до 50 символов.",
    telRequired: "Необходимо ввести Телефон.",
    telInvalid: "Неверный формат номера телефона.",
  };

  function resetModal() {
    showModalState(formState, formState, successState, errorState, "modal");
    form.reset();
    modal.classList.remove("opened");
  }

  document.querySelectorAll(".cta-modal").forEach((button) => {
    button.addEventListener("click", (event) => {
      event.preventDefault();
      resetModal();
      modal.classList.add("opened");
    });
  });

  modal.addEventListener("click", (event) => {
    if (!modalContent.contains(event.target)) {
      resetModal();
    }
  });

  closeButton.addEventListener("click", (event) => {
    event.preventDefault();
    resetModal();
  });

  backButton.addEventListener("click", resetModal);
  retryButton.addEventListener("click", () => {
    if (modal.dataset.source === "feedback") {
      modal.classList.remove("opened");
    } else {
      showModalState(formState, formState, successState, errorState, "modal");
    }
  });

  form.addEventListener("submit", async (event) => {
    event.preventDefault();

    const nameInput = document.querySelector("#form-modal-name");
    form.querySelectorAll(".error-message").forEach((el) => el.remove());

    let isValid = validatePrivacyPolicy(
      privacyPolicyInputModal,
      createErrorMessage
    );

    isValid =
      validateInput(
        nameInput,
        errorMessagesModal.nameRequired,
        errorMessagesModal.nameInvalid,
        validateFio,
        "form-modal-name"
      ) && isValid;
    isValid =
      validateInput(
        phoneInput,
        errorMessagesModal.telRequired,
        errorMessagesModal.telInvalid,
        isValidPhoneNumber
      ) && isValid;

    if (isValid) {
      submitButton.disabled = true;
      buttonArrow.classList.add("active");
      buttonSpinner.classList.add("active");

      const formData = new FormData(form);
      formData.set(
        "tel",
        phoneInput.value
          .split("")
          .filter((i) => i !== " ")
          .join("")
      );

      for (let [key, value] of formData.entries()) {
        console.log("KEYVALUE", key, value);
      }

      const telegramSuccess = await sendToTelegram(
        formData,
        import.meta.env.ALEF_CHAT_ID,
        import.meta.env.ALEF_BOT_TOKEN
      );

      buttonArrow.classList.remove("active");
      buttonSpinner.classList.remove("active");
      submitButton.disabled = false;

      showModalState(
        telegramSuccess ? successState : errorState,
        formState,
        successState,
        errorState,
        "modal"
      );

      form.reset();
    }
  });

  privacyPolicyInputModal.addEventListener("change", () =>
    privacyPolicyInputModal.classList.remove("error")
  );
}

export default modalBtn;
