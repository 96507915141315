import { isValidPhoneNumber, mask } from "./phoneMask/mask.js";
import {
  createErrorMessage,
  validateFio,
  validateInput,
  validateEmail,
  validatePrivacyPolicy,
  showFileError,
  handleFileInput,
  sanitizeComment,
  showModalState,
  sendToTelegram,
} from "./utils/formUtils";

const feedbackForm = () => {
  const form = document.querySelector("#feedback-form");
  const fileInput = document.querySelector(".custom-input-file input");
  const fileInputName = document.querySelector(
    ".custom-input-file .custom-input-file-name"
  );
  const fileDeleteButton = document.createElement("button");
  const privacyPolicyInput = document.querySelector("#feedback-privacy-policy");
  const maxFileSize = 5 * 1024 * 1024;
  const supportedExtensions = ["doc", "docx", "pdf", "jpg", "jpeg", "png"];
  const submitButton = document.querySelector(".btn-submit-feedback");
  const buttonArrow = document.querySelector(".form__arrow");
  const buttonSpinner = document.querySelector(".form__spinner");
  const formState = document.querySelector(".modal__state--form");
  const successState = document.querySelector(".modal__state--success");
  const errorState = document.querySelector(".modal__state--error");
  const modal = document.querySelector("#modal");

  const errorMessages = {
    emailRequired: "Необходимо ввести Email.",
    emailInvalid: "Неверный формат Email.",
    nameRequired: "Необходимо ввести Имя.",
    nameInvalid: "Имя должно быть от 2 до 50 символов.",
    telRequired: "Необходимо ввести Телефон.",
    telInvalid: "Неверный формат номера телефона.",
    fileSizeExceeded: "Максимальный размер файла 5MB.",
    fileTypeInvalid:
      "Поддерживаемые расширения файлов: DOC, DOCX, PDF, JPG, JPEG, PNG.",
  };

  form.setAttribute("novalidate", true);

  const phoneInput = document.querySelector("#feedback-tel");
  if (phoneInput) {
    mask("#feedback-tel");
  }

  const commentInput = document.querySelector("#feedback-message");
  commentInput.setAttribute("maxlength", "1000");

  fileDeleteButton.textContent = "Удалить файл";
  fileDeleteButton.classList.add("delete-file-btn");

  const resetFileInput = () => {
    fileInput.value = "";
    fileInputName.innerText = "";
    fileInput.parentElement.classList.remove("active");
    fileDeleteButton.remove();
    submitButton.disabled = false;
    const errorLabel = document.querySelector("#form-file-input-error");
    if (errorLabel) {
      errorLabel.textContent = "";
      errorLabel.style.display = "none";
    }
  };

  fileInput.addEventListener("change", (e) =>
    handleFileInput(
      e.target.files[0],
      fileInput,
      fileInputName,
      fileDeleteButton,
      maxFileSize,
      supportedExtensions,
      errorMessages,
      showFileError,
      submitButton
    )
  );

  fileDeleteButton.addEventListener("click", (e) => {
    e.preventDefault();
    resetFileInput();
  });

  const resetFormStates = () => {
    form.querySelectorAll(".error-message").forEach((el) => el.remove());
  };

  form.addEventListener("submit", async (e) => {
    e.preventDefault();

    const emailInput = document.querySelector("#feedback-email");
    const nameInput = document.querySelector("#feedback-name");

    resetFormStates();

    let isValid = validatePrivacyPolicy(privacyPolicyInput, createErrorMessage);

    isValid =
      validateInput(
        nameInput,
        errorMessages.nameRequired,
        errorMessages.nameInvalid,
        validateFio,
        "feedback-name"
      ) && isValid;
    isValid =
      validateInput(
        phoneInput,
        errorMessages.telRequired,
        errorMessages.telInvalid,
        isValidPhoneNumber
      ) && isValid;
    isValid =
      validateInput(
        emailInput,
        errorMessages.emailRequired,
        errorMessages.emailInvalid,
        validateEmail
      ) && isValid;

    if (isValid) {
      submitButton.disabled = true;
      buttonArrow.classList.add("active");
      buttonSpinner.classList.add("active");
      const formData = new FormData(form);

      if (!fileInput.files[0] || fileInput.files[0].size === 0) {
        formData.delete("feedback-file");
        formData.set("feedback-file", null);
      } else {
        formData.set("feedback-file", fileInput.files[0]);
      }
      formData.set(
        "tel",
        phoneInput.value
          .split("")
          .filter((i) => i !== " ")
          .join("")
      );
      formData.set("comment", sanitizeComment(commentInput.value));

      for (let [key, value] of formData.entries()) {
        console.log("KEYVALUE", key, value);
      }

      const telegramSuccess = await sendToTelegram(
        formData,
        import.meta.env.ALEF_CHAT_ID,
        import.meta.env.ALEF_BOT_TOKEN
      );
      submitButton.disabled = false;
      buttonArrow.classList.remove("active");
      buttonSpinner.classList.remove("active");
      resetFileInput();

      showModalState(
        telegramSuccess ? successState : errorState,
        formState,
        successState,
        errorState,
        "feedback"
      );

      form.reset();
    }
  });

  privacyPolicyInput.addEventListener("change", () =>
    privacyPolicyInput.classList.remove("error")
  );
};

export default feedbackForm;
