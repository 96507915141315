const data = {
  frontend: {
    Технологии: [
      "JavaScript",
      "TypeScript",
      "HTML 5",
      "CSS 3",
      "LESS/SASS/SCSS",
      "Webpack",
      "Babel",
      "ESLint",
      "Prettier",
      "PostCSS",
      "GraphQL",
      "REST",
      "WebSockets",
      "Service Workers",
      "WebAssembly",
      "Canvas",
      "SVG",
    ],
    Фреймворки: ["React", "VueJS", "Svelte", "Next.js", "Nuxt.js"],
    Библиотеки: [
      "Vue Router",
      "Vuex",
      "Pinia",
      "Vuetify",
      "VeeValidate",
      "Redux",
      "MobX",
      "React Router",
      "Axios",
      "Lodash",
      "Moment.js",
      "Chart.js",
      "Formik",
      "Yup",
      "Styled Components",
      "Emotion",
      "Bootstrap",
      "Tailwind CSS",
      "Material-UI",
      "Ant Design",
      "Semantic UI",
      "Foundation",
      "jQuery",
      "GSAP",
      "Anime.js",
      "Swiper.js",
      "Framer Motion",
      "React Query",
      "React Hook Form",
      "React DnD",
      "React Window",
      "React i18next",
      "Jest",
    ],
  },
  backend: {
    Языки: ["PHP7", "PHP8", "PYTHON", "JavaScript", "TypeScript"],
    Фреймворки: ["Symfony", "Laravel", "Yii", "PHPUnit", "Nest.JS"],
    "CMS & Platforms": ["Bitrix", "WooCommerce (WordPress)", "Genesis"],
    "Базы данных": ["PostgreSQL", "MySQL", "ClickHouse"],
    "Технологии и инструменты": [
      "Doctrine",
      "Composer",
      "RATCHET",
      "Node.JS",
      "LDAP",
      "GraphQL",
      "JWT(jSON WEB TOKENS)",
      "Qauth 2.0",
      "REST",
      "SOAP",
      "WEBSOCKETS",
      "XML",
      "Machine Learning",
      "TENSORFLOW",
      "RabbitMQ",
      "Memcached",
      "Elasticsearch",
      "Kibana",
      "Bitbucket Pipelines",
      "Apache",
      "Gitlab",
      "Docker",
      "Docker Compose",
      "Kubernetes",
      "NGINX",
      "PROMETHEUS",
      "SELENIUM",
      "FIREBASE",
      "Google cloud Platform",
      "VMware",
      "Azure",
      "AWS",
      "Redis",
      "Vagrant",
    ],
  },
  mobile: {
    Языки: ["Kotlin", "Java", "Swift", "Objective-C"],
    "Ios Native": [
      "Snapkit",
      "Ios Apps Clips",
      "Viper",
      "Reactive Cocoa",
      "Combine",
      "Coredata",
      "Carthage",
      "Core animation",
      "Apple Pay",
      "GCD",
      "Cocoa Pods",
      "Alamofire",
      "Uikit",
      "Cleanswift",
      "Storyboard/xib",
      "Coreml",
      "Xcode",
      "Core graphics",
      "Xctest",
      "Swiftui",
      "Keychain",
      "Scenekit",
      "Arkit",
      "Testflight",
    ],
    "Android Native": [
      "Android instant apps",
      "Arcore",
      "Ml kit",
      "MVI",
      "MVVM",
      "Dagger 2",
      "Hilt",
      "SOLID",
      "Jetpack Compose",
      "Glide",
      "Lottie",
      "Firebase",
      "Google Maps",
      "RabbitMQ",
      "Picasso",
      "Room",
      "Realm",
      "Adjust",
      "AppsFlyer",
      "RevenueCat",
      "Android studio",
      "Coroutines",
      "Google Play",
      "Google Billing",
      "Clean Architecture",
      "Retrofit 2",
      "Rxjava 2/3",
      "Android SDK",
      "Agora SDK",
      "Vosimplant SDK",
    ],
  },
};

const tagsController = () => {
  const buttons = Array.from(document.querySelectorAll(".tags-controller-btn"));
  const tagsContainer = document.getElementById("tags-container");

  const createTagElement = (tagName) => {
    const li = document.createElement("li");
    li.textContent = tagName;
    return li;
  };

  const createTagGroupElement = (stackName, techsArray) => {
    const div = document.createElement("div");
    div.className = "tags-controller__group";

    const h4 = document.createElement("h4");
    h4.className = "tags-controller__group-title";
    h4.textContent = stackName;
    div.appendChild(h4);

    const ul = document.createElement("ul");
    ul.className = "tags-list";
    techsArray.forEach((techName) => {
      ul.appendChild(createTagElement(techName));
    });
    div.appendChild(ul);

    return div;
  };

  const renderTagGroup = (techGroup) => {
    tagsContainer.innerHTML = "";
    for (const [stackName, techsArray] of Object.entries(techGroup)) {
      tagsContainer.appendChild(createTagGroupElement(stackName, techsArray));
    }

    setTimeout(() => {
      const tagLists = tagsContainer.querySelectorAll(".tags-list");
      tagLists.forEach((list) => list.classList.add("visible"));
    }, 10);
  };

  const handleButtonClick = (btn) => {
    if (!btn.classList.contains("active")) {
      const activeButton = buttons.find((el) =>
        el.classList.contains("active")
      );
      if (activeButton) activeButton.classList.remove("active");
      btn.classList.add("active");
      renderTagGroup(data[btn.dataset.techGroup]);
    }
  };

  buttons.forEach((btn) => {
    btn.addEventListener("click", () => handleButtonClick(btn));
  });

  renderTagGroup(data["frontend"]);
};

export default tagsController;
