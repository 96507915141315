import { clearNavState, smoothScrollTop } from "./utils/navUtils";

function scrollButton() {
  const scrollTopBtn = document.querySelector(".scroll-button");
  const sectionAbout = document.getElementById("section-about");
  const navEl = document.querySelector("#main-nav");

  if (!scrollTopBtn || !sectionAbout) {
    console.error("Required elements not found in the DOM");
    return;
  }

  const scrollTop = (e) => {
    smoothScrollTop(e);
    clearNavState(navEl);
  };

  const observerOptions = {
    root: null,
    threshold: 0,
  };

  const observerCallback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        scrollTopBtn.classList.add("active");
      } else if (window.scrollY < sectionAbout.offsetTop) {
        scrollTopBtn.classList.remove("active");
      }
    });
  };

  const observer = new IntersectionObserver(observerCallback, observerOptions);
  observer.observe(sectionAbout);

  scrollTopBtn.addEventListener("click", (e) => {
    scrollTopBtn.classList.remove("active");
    scrollTop(e);
  });
}

export default scrollButton;
