import { isMobile } from "./mq.js";
import { clearNavState, smoothScrollTop } from "./utils/navUtils.js";

const nav = () => {
  let lastActiveSectionId;
  const navEl = document.querySelector("#main-nav");
  const pageHeader = document.querySelector("#page-header");
  const navItems = Array.from(navEl.querySelectorAll(".nav-link"));
  const logo = document.querySelector(".page-header__logo");
  const sections = navItems
    .map((item) => {
      const section = document.querySelector(item.hash);
      return section || null;
    })
    .filter(Boolean);

  const scrollToTop = (e) => {
    smoothScrollTop(e);
    lastActiveSectionId = clearNavState(navEl);
  };

  if (logo) {
    logo.addEventListener("click", scrollToTop);
  }

  const updateNav = () => {
    const fromTop = document.documentElement.scrollTop;
    const isAtTop = fromTop === 0;

    pageHeader.classList.toggle("page-scrolled", !isAtTop);

    if (isAtTop) {
      const currentActive = navEl.querySelector("a.active");
      if (currentActive) {
        currentActive.classList.remove("active");
      }
      lastActiveSectionId = null;
      return;
    }

    const scrolledSections = sections.reduce((acc, section) => {
      if (section && section.offsetTop < fromTop + window.innerHeight / 2) {
        acc.push(section);
      }
      return acc;
    }, []);

    const activeSection = scrolledSections[scrolledSections.length - 1];
    const activeSectionId = activeSection ? activeSection.id : null;

    if (activeSectionId && lastActiveSectionId !== activeSectionId) {
      lastActiveSectionId = activeSectionId;
      const currentActive = navEl.querySelector("a.active");
      if (currentActive) {
        currentActive.classList.remove("active");
      }
      const newActive = navItems.find(
        (item) => item.hash === `#${activeSectionId}`
      );
      if (newActive) {
        newActive.classList.add("active");
      }
    }
  };

  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  };

  const handleScroll = debounce(updateNav, 100);

  document.addEventListener("DOMContentLoaded", () => {
    requestAnimationFrame(updateNav);
  });

  document.addEventListener("scroll", handleScroll);

  const navToggles = Array.from(document.querySelectorAll(".nav-toggle"));
  const mainNav = document.querySelector("#main-nav");

  const toggleMainNav = () => {
    mainNav.classList.toggle("opened");
    pageHeader.classList.toggle("menu-opened");
  };

  navToggles.forEach((toggle) => {
    toggle.addEventListener("click", toggleMainNav);
  });

  navItems.forEach((item) => {
    item.addEventListener("click", (e) => {
      e.preventDefault();
      const targetSection = document.querySelector(item.hash);
      if (targetSection) {
        const offsetTop = targetSection.offsetTop - pageHeader.offsetHeight;

        window.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });

        if (isMobile()) {
          toggleMainNav();
        }
      }
    });
  });

  window.addEventListener("load", () => {
    setTimeout(updateNav, 100);
  });
};

export default nav;
