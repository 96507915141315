import { maskList } from "./mask-list";

export const mask = (selector) => {
    function setMask() {
        let matrix = '+###############';
        maskList.forEach(item => {
            let code = item.code.replace(/[\s#]/g, ''),
                phone = this.value.replace(/[\s#-)(]/g, '');
            if (phone.includes(code)) {
                matrix = item.code;
            }
        });

        let i = 0,
            val = this.value.replace(/\D/g, '');

        this.value = matrix.replace(/(?!\+)./g, function (a) {
            return /[#\d]/.test(a) && i < val.length ? val.charAt(i++) : i >= val.length ? '' : a;
        });
    }

    function onFocus() {
        if (this.value === '') {
            this.value = '+';
        }
        setMask.call(this);
    }

    function onBlur() {
        if (this.value.length === 1 && this.value === '+') {
            this.value = '';
        } else {
            setMask.call(this);
        }
    }

    let inputs;
    if (typeof selector === 'string') {
        inputs = document.querySelectorAll(selector);
    } else if (selector instanceof HTMLElement) {
        inputs = [selector];
    } else {
        console.error('Invalid selector provided to mask function');
        return;
    }

    inputs.forEach(input => {
        input.addEventListener('input', setMask);
        input.addEventListener('focus', onFocus);
        input.addEventListener('blur', onBlur);
    });
};


export const isValidPhoneNumber = (inputValue) => {
    const normalizedValue = inputValue.replace(/[^+\d]/g, '');
    const matchedMask = maskList.find(({ code }) => normalizedValue.startsWith(code.split(' ')[0]));

    if (!matchedMask) {
        console.log("No matching mask found for:", normalizedValue);
        return false;
    }

    const [staticPart, ...dynamicParts] = matchedMask.code.split(' ');
    const dynamicPart = dynamicParts.join('');

    const hashCount = (dynamicPart.match(/#/g) || []).length;

    const dynamicInput = normalizedValue.slice(staticPart.length).replace(/\D/g, '');
    const numericCount = dynamicInput.length;

    if (numericCount < hashCount) {
        console.log("Input has fewer numbers than required by the mask.");
        return false;
    }

    return true;
};
