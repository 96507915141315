import nav from "./js/blocks/nav";
import initCaseSwiperObserver from "./js/blocks/case-swiper";
import initSliders from "./js/blocks/sliders";
import tagsController from "./js/blocks/tags-controller";
import feedbackForm from "./js/blocks/feedback-form";
import scrollButton from "./js/blocks/scroll-button";
import modalBtn from "./js/blocks/modal";
import observer from "./js/blocks/observer";
import { checkViewportAndCreateDots } from "./js/blocks/dots";
import { initMarqueeSliderObserver } from "./js/blocks/marquee";
import favicon from "./js/blocks/favicon";

document.addEventListener("DOMContentLoaded", async () => {
  favicon();
  nav();
  modalBtn();
  scrollButton();
  observer();
  initSliders();
  tagsController();
  feedbackForm();
  checkViewportAndCreateDots();
  window.addEventListener("resize", checkViewportAndCreateDots);

  initMarqueeSliderObserver();
  initCaseSwiperObserver();
});
