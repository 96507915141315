import Swiper from "swiper";
import { Navigation } from "swiper/modules";
import { isXL } from "./mq.js";

import "swiper/css";

const initSwiper = (selector, options) => {
  const swiper = new Swiper(selector, options);
  return swiper;
};

const initServicesSwiper = () => {
  const servicesSwiper = initSwiper(".services-swiper", {
    modules: [Navigation],
    loop: false,
    slidesPerView: 1,
    navigation: {
      nextEl: ".services-swiper__swiper-button-next",
      prevEl: ".services-swiper__swiper-button-prev",
    },
    on: {
      init: function () {
        const slides = document.querySelectorAll(
          ".services-swiper .swiper-slide"
        );
        const images = [
          "/static/images/service-item-hover/web-services.png",
          "/static/images/service-item-hover/mobile-apps.png",
          "/static/images/service-item-hover/chat-bots.png",
          "/static/images/service-item-hover/tech-support.png",
          "/static/images/service-item-hover/crm-tools.png",
          "/static/images/service-item-hover/design.png",
        ];
        slides.forEach((slide, index) => {
          const serviceItem = slide.querySelector(".service-item");
          if (serviceItem) {
            serviceItem.dataset.bgimage = images[index % images.length];
          }
        });
      },
    },
  });
};

const initFeedbackSwiper = () => {
  const feedbackSwiper = initSwiper(".feedback-swiper", {
    modules: [Navigation],
    loop: false,
    slidesPerView: 1,
    spaceBetween: 14,
    navigation: {
      nextEl: ".feedback-swiper__swiper-button-next",
      prevEl: ".feedback-swiper__swiper-button-prev",
    },
    breakpoints: {
      768: {
        slidesPerView: 2.5,
        spaceBetween: 30,
      },
    },
  });

  feedbackSwiper.on("slideChange", (swiper) => {
    const lastSlideIndex = swiper.slides.length - 1;
    const slidesToSubtract = isXL() ? 3 : 2;

    feedbackSwiper.allowSlideNext =
      swiper.activeIndex !== lastSlideIndex - slidesToSubtract;
  });
};

const initMediaSwiper = () => {
  const mediaSwiper = initSwiper(".media-swiper", {
    modules: [Navigation],
    loop: false,
    slidesPerView: 1,
    spaceBetween: 14,
    navigation: {
      nextEl: ".media-swiper__swiper-button-next",
      prevEl: ".media-swiper__swiper-button-prev",
    },
    breakpoints: {
      768: {
        slidesPerView: "auto",
        spaceBetween: 30,
      },
    },
  });

  mediaSwiper.on("slideChange", (swiper) => {
    const lastSlideIndex = swiper.slides.length - 1;
    const slidesToSubtract = isXL() ? 3 : 2;

    mediaSwiper.allowSlideNext =
      swiper.activeIndex !== lastSlideIndex - slidesToSubtract;
  });
};

const initSliders = () => {
  initServicesSwiper();
  initFeedbackSwiper();
  initMediaSwiper();
};

export default initSliders;
